import React from 'react';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { PersonalInformationComponent } from '../../components/PersonalInformation';
import { Sidebar } from '../../components/Sidebar';

export function PersonalInformation() {
  const style = {
    paddingTop: '65px',
  };
  
  return (
    <div className="bg-gray-200 h-screen overflow-hidden flex items-center justify-center">
      <div className="w-full h-full">
        <Header />
        <div className="flex flex-no-wrap min-h-screen pt-65px" style={style}>
          <Sidebar />
          <div className="grow md:w-4/5 w-11/12 text-center bg-gray-300">
            <PersonalInformationComponent />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}