import React from 'react';
import { AiOutlineDashboard, AiOutlineFileText } from "react-icons/ai";
import { BsPerson } from 'react-icons/bs';
import { MdOutlineConnectWithoutContact, MdOutlinePostAdd } from 'react-icons/md';
import { TfiCrown } from 'react-icons/tfi';
import { GiDeathStar, GiUfo } from 'react-icons/gi';
import { HiBuildingOffice2 } from 'react-icons/hi2';
import { FaHubspot, FaGamepad } from 'react-icons/fa';
import { IoLinkSharp, IoLanguageSharp } from 'react-icons/io5';

export function Sidebar() {
  return (
    <div className="w-64 relative bg-custom-gray-800 shadow flex-col justify-between sm:flex">
      <div>
        <div id="logo-container">
          <div id="logo-background" className="text-white text-center"></div>
          <h1 id="logo-title" className="text-white text-center text-4xl font-['Tangerine']">
            Curriculum Admin
          </h1>
        </div>
        <div id="mainMenu">
          <ul className="relative pb-5">
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-white mr-5 hover:text-gray-300">
                <AiOutlineDashboard className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/dashboard">Dashboard</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <BsPerson className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/personal-information">Personal Information</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <AiOutlineFileText className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/history">History</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <MdOutlineConnectWithoutContact className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/contact">Contact</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <FaGamepad className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/hobbies">Hobbies</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <TfiCrown className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/habilities">Habilities</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <GiDeathStar className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/actual-technologies">Actual Technologies</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <GiUfo className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/technologies">Technologies</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <HiBuildingOffice2 className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/career">Career</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <FaHubspot className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/projects">Projects</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <IoLinkSharp className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/links">Links</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <IoLanguageSharp className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/languages">Languages</a>
            </li>
            <li className="flex text-white items-center text-md py-4 px-8 cursor-pointer hover:bg-custom-gray-900">
              <span className="text-gray-400 mr-5 hover:text-gray-300">
                <MdOutlinePostAdd className='w-6 h-6 text-white hover:text-gray-300' />
              </span> <a href="/blog">Blog</a>
            </li>
            {/* <span className="absolute text-white right-5 bottom-5">
              <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </span> */}
          </ul>
        </div>
      </div>
    </div>
  );
}