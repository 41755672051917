import React, { useEffect } from 'react';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { Dashboard } from '../../components/Dashboard/index';
import { useNavigate } from 'react-router-dom';

export function Main() {
  const style = {
    paddingTop: '65px',
  };

  const navigate = useNavigate();

  const handleIsLogged = () => {
    const secretLocalStorage = localStorage.getItem('secret');

    if (secretLocalStorage) {
      const parsedData = JSON.parse(secretLocalStorage);

      const now = Math.floor(Date.now() / 1000);

      if (parsedData?.expires_at && (parsedData?.expires_at < now)) {
        navigate(`/`, { replace: true });
      }
    } else {
      navigate(`/`, { replace: true });
    }
  } 

  useEffect(() => {
    handleIsLogged()
  }, []);

  return (
    <div className="bg-gray-200 h-screen overflow-hidden flex items-center justify-center">
      <div className="w-full h-full">
        <Header />
        <div className="flex flex-no-wrap  min-h-screen pt-65px" style={style}>
          <Sidebar />
          <div className="grow md:w-4/5 w-11/12 text-center bg-gray-300">
            <Dashboard />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}