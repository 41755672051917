import React from 'react';
import { IoMail, IoMailUnread } from 'react-icons/io5';
import { RxAvatar } from 'react-icons/rx';
import { FaSearch } from  'react-icons/fa';
import { MdOutlineLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('secret');
    localStorage.removeItem('userData');

    navigate(`/`, { replace: true });
  }

  return (
    <div className="py-4 h-30 text-center text-gray bg-white z-50 shadow-lg flex flex-row fixed top-0 right-0 left-0">
      <div className="grow"></div>
      <div className="px-3 flex items-center">
        <input type="text" placeholder="Search" className="input input-sm hover:bg-gray-200 hover:border-gray-500 text-gray-800 w-full max-w-xs border border-gray-800" /> 
        <div className="tooltip" data-tip="Search">
          <FaSearch className='ml-3 w-5 h-5 text-gray-800 hover:text-gray-500 cursor-pointer' />
        </div>
      </div>
      <div className="px-2 items-center"> 
        <div className="tooltip" data-tip="Notifications">
          <IoMail className='w-6 h-6 text-gray-800 hover:text-gray-500 cursor-pointer' />
        </div>
      </div>
      <div className="px-2 items-center">
        <div className="tooltip" data-tip="Personal Configurations">
          <RxAvatar className='w-6 h-6 text-gray-800 hover:text-gray-500 cursor-pointer' />
        </div>
      </div>
      <div className="px-2 mr-5 items-center">
        <div className="tooltip" data-tip="Sign out">
          <MdOutlineLogout onClick={handleLogout} className='w-6 h-6 text-gray-800 hover:text-gray-500 cursor-pointer' />
        </div>
      </div>
    </div> 
  );
}