import React from 'react';
import { useNavigate } from 'react-router-dom';

export function Logout() {
  const navigate = useNavigate();

  localStorage.removeItem('secret');
  localStorage.removeItem('userData');

  navigate(`/`, { replace: true });

  return (
    <h2>Logout</h2>
  )
}