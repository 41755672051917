import React from 'react';

export function Dashboard() {
  return (
    <div className="min-h-screen w-12/12 p-5">
      <div className="bg-white my-5 p-5 shadow-md">
        <div className="border-b-4 p-2 border-gray-400">
          <h2 className="font-bold text-2xl text-left indent-2.5">Visitors</h2>
        </div>
        <div className="p-2">
          <h1>Content</h1>
        </div>
      </div>
      <div className="bg-white my-5 p-5 shadow-md">
        <div className="border-b-4 p-2 border-gray-400">
          <h2 className="font-bold text-2xl text-left indent-2.5">Demographic Visitors</h2>
        </div>
        <div className="p-2">
            <h1>Content</h1>
        </div>
      </div>

      <div className="flex my-5">
        <div className="bg-white w-1/2 p-5 shadow-md">
          <div className="border-b-4 p-2 border-gray-400">
            <h2 className="font-bold text-2xl text-left indent-2.5">Most Accessed Posts</h2>
          </div>
          <div className="my-5 text-left indent-10">
            <ul>
              <li>Post 01</li>
              <li>Post 02</li>
              <li>Post 03</li>
            </ul>
          </div>
        </div>
        <div className="bg-white w-1/2 ml-6 p-5 shadow-md">
          <div className="border-b-4 p-2 border-gray-400">
            <h2 className="font-bold text-2xl text-left indent-2.5">Most Accessed Pages</h2>
          </div>
          <div className="my-5 text-left indent-10">
            <ul>
              <li>Page 01</li>
              <li>Page 02</li>
              <li>Page 03</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="bg-white w-1/2 p-5 shadow-md">
          <div className="border-b-4 p-2 border-gray-400">
            <h2 className="font-bold text-2xl text-left indent-2.5">Last Posts</h2>
          </div>
          <div className="my-5 text-left indent-10">
            <ul>
              <li>Post 01</li>
              <li>Post 02</li>
              <li>Post 03</li>
            </ul>
          </div>
        </div>
        <div className="bg-white w-1/2 ml-6 p-5 shadow-md">
          <div className="border-b-4 p-2 border-gray-400">
            <h2 className="font-bold text-2xl text-left indent-2.5">Title</h2>
          </div>
          <div className="my-5 text-left indent-10">
            <ul>
              <li>Page 01</li>
              <li>Page 02</li>
              <li>Page 03</li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  );
}