import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login } from './pages/Login/index';
import { Main } from './pages/Main';
import { PersonalInformation } from './pages/PersonalInformation/index';
import { Habilities } from './pages/Habilities/index';
import { HabilitiesDetails } from './pages/HabilitiesDetails/index';
import { Logout } from './pages/Logout';

function App() {
  return (
    <Router>
      <Routes>
        <Route index path="/" element={<Login />} />
        <Route path="/dashboard" element={<Main />} />
        <Route path="/personal-information" element={<PersonalInformation />} />
        <Route path="/habilities" element={<Habilities />} />
        <Route path="/habilities/details/:id" element={<HabilitiesDetails />} />
        <Route path="/logout" element={<Logout />} />
      </Routes> 
    </Router>
  );
}

export default App;
