import React from 'react';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { Sidebar } from '../../components/Sidebar';
import { HabilitiesComponent } from '../../components/Habilities/index';

export function Habilities() {
  const style = {
    paddingTop: '65px',
  };

  return (
    <div className="bg-gray-200 h-screen overflow-y-auto flex items-center justify-center basis-full">
      <div className="w-full h-full">
        <Header />
        <div className="flex flex-no-wrap min-h-screen pt-65px" style={style}>
          <Sidebar />
          <div className="grow md:w-4/5 w-11/12 text-center bg-custom-gray-300">
            <HabilitiesComponent />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}