import axios from 'axios';
import { BACKEND_API_URL } from "./constants/api_url";
import { IHabilities } from "./interfaces/habilities.interface";
import httpClient from "../common/http-client";

class HabilitiesService {
  async getHabilitiesAdmin(filters?: any): Promise<IHabilities> {
      let apiUrl = `${BACKEND_API_URL}/habilities/admin/list?page=${filters?.page}&limit=${filters?.limit}`;

      if (filters.orderBy) {
        apiUrl = `${apiUrl}&orderBy=${filters.orderBy}`;
      }

      if (filters.direction) {
        apiUrl = `${apiUrl}&direction=${filters.direction}`;
      }

      const result = await fetch(`${apiUrl}`);

      const data = await result.json();

      return data;
  }

  async searchHabilitiesAdmin(filters?: any): Promise<IHabilities> {
    let apiUrl = `${BACKEND_API_URL}/habilities/admin/search?searchTerm=${filters?.searchTerm}`;
    apiUrl = `${apiUrl}&page=${filters?.page}&limit=${filters?.limit}`;

    if (filters.orderBy) {
      apiUrl = `${apiUrl}&orderBy=${filters.orderBy}`;
    }

    if (filters.direction) {
      apiUrl = `${apiUrl}&direction=${filters.direction}`;
    }

    const result = await fetch(`${apiUrl}`);

    const data = await result.json();


    return data;
  }

  async updateStatusAdmin(filters?: any): Promise<boolean> {
    try {
      const apiUrl = `/habilities/admin/status/${filters.id}`;

      const payload = {
        status: filters.status,
      };

      // const res = await apiClient.put(`/tutorials/${id}`, putData, {
      //   headers: {
      //     "x-access-token": "token-value",
      //   },
      // });

      const result = await httpClient.put(apiUrl, payload);

      console.log('RESULTS UPDATE STATUS ', result);

      // const result = await httpClient.put(apiUrl, payload, {
      //   headers: {
      //     "Authorization": `Bearer ${BACKEND_API_TOKEN}`,
      //   },
      // });

      // const data = await result.json();


      // return data;
      return true;
    } catch (err: any) {
      console.log('err ', err.message);
      return false;
    }
  }
}

const habilitiesService = new HabilitiesService();

export default habilitiesService;