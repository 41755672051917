import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../Grid/index';
import habilitiesService from '../../services/habilities.service';
import { IGridStatus } from '../Grid/interfaces/grid.status.interface';
import { useNavigate } from 'react-router-dom';

export function HabilitiesComponent() {
  const navigate = useNavigate();
  
  const handleIsLogged = () => {
    const secretLocalStorage = localStorage.getItem('secret');

    if (secretLocalStorage) {
      const parsedData = JSON.parse(secretLocalStorage);

      const now = Math.floor(Date.now() / 1000);

      if (parsedData?.expires_at && (parsedData?.expires_at < now)) {
        navigate(`/`, { replace: true });
      }
    } else {
      navigate(`/`, { replace: true });
    }
  } 

  useEffect(() => {
    handleIsLogged()
  }, []);

  const initHabilities: any[][] = [[]];

  const [data, setData] = useState(initHabilities);

  const [paginate, setPaginate] = useState({
    total: 0,
    limit: 0,
    range: [0,0],
    actualPage: 0,
    numberPages: 0,
    orderBy: '',
    direction: ''
  });

  const habilitiesData = useCallback(async (filters: any) => {
    try {     
      const data = await habilitiesService.getHabilitiesAdmin({page: filters.page, limit: filters.limit});

      const treatedData = data.data.map((item) => {
        return [
          item.id,
          item.name,
          item.language,
          item.active ? 'active' : 'disable',
          item.order,
          item.createdAt,
          item.updatedAt
        ];
      });

      if (treatedData && treatedData.length > 0) {
        setData(treatedData);
      } else {
        setData(initHabilities);
      }

      setPaginate({
        total: data.total,
        limit: data.limit,
        range: data.range,
        actualPage: data.actualPage,
        numberPages: data.numberPages,
        orderBy: data.orderBy,
        direction: data.direction,
      })
    } catch (err) {
      console.log('ERROR', err);
    }
  }, []);

  const habilitiesSearchData = useCallback(async (filters: any) => {
    try {
      const data = await habilitiesService.searchHabilitiesAdmin({
        searchTerm: filters.searchTerm, 
        page: filters.page,
        limit: filters.limit,
      });

      const treatedData = data.data.map((item) => {
        return [
          item.id,
          item.name,
          item.language,
          item.active ? 'active' : 'disable',
          item.order,
          item.createdAt,
          item.updatedAt
        ];
      });

      if (treatedData && treatedData.length > 0) {
        setData(treatedData);
      } else {
        setData([]);
      }

      setPaginate({
        total: data.total,
        limit: data.limit,
        range: data.range,
        actualPage: data.actualPage,
        numberPages: data.numberPages,
        orderBy: data.orderBy,
        direction: data.direction,
      })
    } catch (err) {
      console.log('ERROR', err);
    }
  }, []);

  const habilitiesDeleteRow = useCallback(async (filters: any) => {
    console.log("DELETE ROW");
    console.log('FILTERS ', filters);
  }, []);


  const habilitiesUpdateState = useCallback(async (filters: IGridStatus) => {
    console.log("UPDATE STATE");
    console.log('FILTERS ', filters);
    try {
      const data = await habilitiesService.updateStatusAdmin({
        id: filters.id, 
        status: filters.state,
      });

      if (!data) {
        return;
      }

      if (filters.searchTerm && filters.searchTerm !== '') {
        habilitiesSearchData({
          searchTerm: filters.searchTerm, 
          page: filters.pagination.actualPage,
          limit: filters.pagination.limit,
        });
      } else {
        habilitiesData({
          page: filters.pagination.actualPage,
          limit: filters.pagination.limit,
        });
      }

      console.log('UPDATE STATUS DATA ', data);
    } catch (err) {
      console.log('ERROR', err);
    }
  }, []);

  useEffect(() => {
    habilitiesData({page: 1, limit: 5});
  }, []);

  const columns = ['Id', 'Name', 'Language', 'Status', 'Order', 'Create', 'Update'];

  return (
    <Grid 
      title="Habilities" 
      data={data} 
      columns={columns} 
      pagination={paginate} 
      redirectTo="/habilities/details"
      searchFunction={habilitiesSearchData} 
      clearFunction={habilitiesData}
      updateStateFunction={habilitiesUpdateState}
      deleteEntry={habilitiesDeleteRow}
    />
  );
}