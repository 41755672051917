import React from 'react';

interface HabilitiesDetailsComponentState {
  data: any;
}

export function HabilitiesDetailsComponent(state: HabilitiesDetailsComponentState) {
  console.log("STATE HABILITIES DETAILS COMPONENT STATE ", state);

  return (
    <h2>Habilities Details Components Id: {state.data.id}</h2>
  );
}