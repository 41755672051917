import React from 'react';
import { MdClear } from 'react-icons/md';
import { GrView } from 'react-icons/gr';
import  { useNavigate } from 'react-router-dom'
import { IGridPagination } from '../interfaces/grid.pagination.interface';

interface IGridRowState {
  searchTerm?: string;
  pagination: IGridPagination;
  row: any;
  redirectTo: string;
  updateStateFunction: (filters: any) => {};
  deleteEntry: (filters: any) => {};
}

export function GridRow(state: IGridRowState) {
  let tdKey = 0;

  const navigate = useNavigate();

  const handleViewDetails = (filters: any) => {
    console.log("VIEW DETAILS");
    console.log("ID ROW ", filters.id);
    navigate(`${state.redirectTo}/${filters.id}`, { replace: true });
  }
  // console.log('DATA GRID ROW ', state);
  return (
    <tr key={state.row[0]} className="bg-white border-b cursor-pointer hover:bg-gray-200">
      {state.row && state.row.map((item: any) => {
          tdKey += 1;
          return <td key={tdKey} className="font-light px-6 py-4">{item}</td>
        })
      }
      <td className="font-light px-6 py-4 flex">
        <div>
          <div className="tooltip" data-tip="View Row">
            <GrView onClick={() => { handleViewDetails({id: state.row[0]}); } } className="cursor-pointer hover:text-gray-500 w-6 h-6" />
          </div>
        </div>
        <div className='ml-3'>
          <div className="tooltip" data-tip="Delete Row">
            <MdClear onClick={async () => { await state.deleteEntry({id: state.row[0]})}} className="cursor-pointer hover:text-gray-500 w-6 h-6" />
          </div>
        </div>

        <div className='ml-3'>
          <div className="tooltip" data-tip="Active / Inactive Item">
            {state.row[3] === 'active' && (
              <input onClick={async () => { await state.updateStateFunction({id: state.row[0], state:'inactive', pagination: state.pagination, searchTerm: state.searchTerm}); } } type="checkbox" className="toggle toggle-success" checked />
            )}
            
            {state.row[3] !== 'active' && (
              <input onClick={async () => { await state.updateStateFunction({id: state.row[0], state:'active', pagination: state.pagination, searchTerm: state.searchTerm}); } } type="checkbox" className="toggle toggle-success" />
            )}
          </div>  
        </div>
      </td>
    </tr>
  );
}
