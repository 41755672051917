import React from 'react';

export function Footer() {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-40 px-4 py-3 text-center text-white bg-custom-gray-800">
      <div className="flex">
          <div className="w-64 sm:relative shadow md:h-full flex-col justify-between hidden sm:flex">
          </div>
          <div className="grow md:w-4/5 w-11/12 text-center">
            <h2>Copyright© 2022 - Curriculum Admin - Version - 1.0 - by Willians Franco - Todos os direitos reservados</h2>
          </div>
      </div>
    </div>
  );
}