import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/auth.service';

export function Login() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleReset = (event: any) => {
    event.preventDefault();

    setLogin('');
    setPassword('');
  }

  const handleSubmit = useCallback(async (event: any) => {
    event.preventDefault();

    const data = await authService.login({
      login: login,
      password: password
    });
    
    if (data.token) {
      const userData = await authService.profile();

      if (!userData) {
        navigate(`/`, { replace: true });
      }

      navigate(`/dashboard`, { replace: true });
    }
    
  }, [login, password]);

  return (
    <div className='bg-custom-gray-100 h-screen'>
      <div className="flex flex-row flex-nowrap justify-center items-center min-h-screen">
        <div>
          <div className="my-5 text-5xl font-['Tangerine']">
            <span>Curriculum Admin</span>
          </div>
          <div className="min-w-250 w-250 border-solid border-2 border-custom-gray-900 bg-white rounded-lg p-10">
            <form onSubmit={handleSubmit}>
              <input
                type="text" 
                placeholder="Login" 
                id="login" 
                name="login" 
                value={login} 
                onChange={(event) =>
                  setLogin(event.target.value)
                } 
                className="input input-bordered w-full mb-5" 
                required 
              />
              <input 
                type="password"
                placeholder="Password"
                id="password" 
                name="password" 
                value={password} 
                onChange={(event) =>
                  setPassword(event.target.value)
                } 
                className="input input-bordered w-full mb-5" 
                required 
              />
              <div className="w-full flex place-content-between">
                <button type='submit' className="btn btn-primary mt-5">Sign In</button>
                <button className="btn btn-primary mt-5" onClick={handleReset}>Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}