import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function PersonalInformationComponent() {
  const navigate = useNavigate();
  
  const handleIsLogged = () => {
    const secretLocalStorage = localStorage.getItem('secret');

    if (secretLocalStorage) {
      const parsedData = JSON.parse(secretLocalStorage);

      const now = Math.floor(Date.now() / 1000);

      if (parsedData?.expires_at && (parsedData?.expires_at < now)) {
        navigate(`/`, { replace: true });
      }
    } else {
      navigate(`/`, { replace: true });
    }
  } 

  useEffect(() => {
    handleIsLogged()
  }, []);

  return (
    <h2>Personal Info</h2>
  );
}