import { BACKEND_API_URL } from "./constants/api_url";
import httpClient from "../common/http-client";

class AuthService {
  async login(data?: any): Promise<any> {
    try {
      let apiUrl = `${BACKEND_API_URL}/auth/login`;

      const payload = {
        username: data.login,
        password: data.password
      }

      const result = await httpClient.post(apiUrl, payload);

      if (result.data.access_token) {
        localStorage.setItem('secret', JSON.stringify({
          token: result.data.access_token,
          expires_at: result.data.expires_at
        }));
        return {
          token: result.data.access_token,
          expires_at: result.data.expires_at,
        };
      }

      return false;
    } catch (err: any) {
      return false;
    }
  }

  async profile(): Promise<any> {
    try {
      let apiUrl = `${BACKEND_API_URL}/auth/profile`;

      const token = localStorage.getItem('secret');

      if (token) {
        const tokenData = JSON.parse(token);

        const config = {
          headers: {
            Authorization: `Bearer ${tokenData.token}`
          }
        }

        const result = await httpClient.get(apiUrl, config);

        if (result.data) {
          localStorage.setItem('userData', JSON.stringify(result.data));
          return result.data;
        }
      }

      return false;
    } catch (err: any) {
      return false;
    }
  }
}

const authService = new AuthService();

export default authService;