import React, { useCallback, useEffect, useState } from 'react';
import { IGridState } from './interfaces/grid.state.interface';
import { AttachEmail } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { FaSearch } from 'react-icons/fa';
import { MdClear } from 'react-icons/md';
import { GridRow } from './GridRow';

export function Grid(state: IGridState) {
  // console.log('ACTUAL PAGE INIT STATE: ', state);

  const arrayLimitsOptions = [5, 10, 30, 50, 100, 200];

  const [limitOptions, setLimitOptions] = useState(arrayLimitsOptions);

  const [searchData, setSearchData] = useState('');

  const [actualPage, setActualPage] = useState(state.pagination.actualPage);

  const [actualLimit, setActualLimit] = useState(state.pagination.limit);

  const [isFirstPage, setFirstPage] = useState(true);

  const [isLastPage, setLastPage] = useState(false);

  let keyThIndex = 0;
  let keyGridIndex = 0;

  const handleChangeSearchTerm = (event: any) => {
    setSearchData(event.target.value);
  }

  const handleChangeActualLimit = (event: any) => {
    setActualPage(1);
    setActualLimit(event.target.value);
  }

  const handleIsFirstPage = () => {  
    if (actualPage === 1) {
      setFirstPage(true);
    } else {
      setFirstPage(false);
    }
  }

  const handleIsLastPage = () => {
    if (actualPage === state.pagination.numberPages) {
      setLastPage(true);
    } else {
      setLastPage(false);
    }
  }

  const handleExecuteSearch = useCallback(async () => {
    await state.searchFunction({
      searchTerm: searchData, 
      page: actualPage,
      limit: actualLimit,
    });
  }, [searchData, state, actualPage, actualLimit]);

  const handleExecuteClear = useCallback(async () => {
    setSearchData('');
    await state.clearFunction({page: 1, limit: actualLimit});
  }, []);

  const handleChangeItemsPerPageLimit = useCallback(async () => {
    await state.clearFunction({page: 1, limit: actualLimit});
  }, [state, actualPage, actualLimit]);

  const handleExecuteCallFirstPage = useCallback(async (searchData?: string) => {
    setActualPage(1);

    if (searchData && searchData !== '') {
      await state.searchFunction(searchData);
    } else {
      await state.clearFunction({page: 1, limit: actualLimit});
    }
  }, [state, actualLimit]);

  const handleExecuteCallNextPage = useCallback(async (searchData?: string) => {
    const tempActualPage = actualPage === 0 ? 1 : actualPage;

    const page = tempActualPage + 1;

    setActualPage(page);

    if (searchData && searchData !== '') {
      await state.searchFunction(searchData);
    } else {
      await state.clearFunction({page: page, limit: actualLimit});
    }
  }, [state, actualPage, actualLimit]);


  const handleExecuteCallPreviousPage = useCallback(async (searchData?: string) => {
    const page = actualPage - 1;

    setActualPage(page);

    if (searchData && searchData !== '') {
      await state.searchFunction(searchData);
    } else {
      await state.clearFunction({page: page, limit: actualLimit});
    }
  }, [state, actualPage, actualLimit]);


  const handleExecuteCallLastPage = useCallback(async (searchData?: string) => {
    setActualPage(state.pagination.numberPages);

    if (searchData && searchData !== '') {
      await state.searchFunction(searchData);
    } else {
      await state.clearFunction({page: state.pagination.numberPages, limit: actualLimit});
    }
  }, [state, actualLimit]);

  useEffect(() => {
    setActualLimit(5);
    setActualPage(1);
    handleIsFirstPage();
    handleIsLastPage();
  }, []);

  useEffect(() => {
    handleIsFirstPage();
    handleIsLastPage();
  }, [isFirstPage, isLastPage]);

  useEffect(() => {
    handleChangeItemsPerPageLimit();
  }, [actualLimit]);

  useEffect(() => {
    handleIsFirstPage();
    handleIsLastPage();
  }, [actualPage, actualLimit]);

  return (
    <div className="min-h-screen w-12/12 p-5">
      <div className="border-b-4 p-2 border-gray-400 bg-white shadow-md">
        <h2 className="font-bold text-2xl text-left indent-2.5">{state.title}</h2>
      </div>
      <div className="bg-white my-5 p-5 shadow-md">
        <div className="flex flex-row-reverse text-gray-800 py-3 content-center items-center">
          <div className="">
            <div className="tooltip" data-tip="Download">
              <CloudDownloadIcon className="cursor-pointer hover:text-gray-500 w-6 h-6" />
            </div>
          </div>
          <div className="mr-5">
            <div className="tooltip" data-tip="Send By Email">
              <AttachEmail className="cursor-pointer hover:text-gray-500 w-6 h-6" />
            </div>
          </div>
          <div className="mr-5">
            <div className="tooltip" data-tip="Clear Search">
              <MdClear className="cursor-pointer hover:text-gray-500 w-6 h-6" onClick={async () => {await handleExecuteClear();}} />
            </div>
          </div>
          <div className="mr-5">
            <div className="tooltip" data-tip="Search">
              <FaSearch className="cursor-pointer hover:text-gray-500 w-6 h-6" onClick={async () => {await handleExecuteSearch();}} />
            </div>
          </div>
          <div className="mr-2">
            <input type="text" value={searchData} onChange={handleChangeSearchTerm} placeholder="Search" className="input input-sm hover:bg-gray-200 hover:border-gray-500 text-gray-800 w-full max-w-xs border border-gray-800" />
          </div>
        </div> 
        {state.data && state.data.length > 0 && (
          <div>
            <div>
              <table className="table-auto border-collapse text-center">
                <thead className="border-b bg-custom-gray-900 text-white">
                  <tr className="bg-custom-gray-900">
                    {state.columns && state.columns.map((item) => {
                      keyThIndex += 1;
                      return <th key={keyThIndex} className="px-6 py-4 bg-custom-gray-900">{item}</th>
                    })}
                    <th className="px-6 py-4">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-custom-gray-900">
                  {state.data && state.data.length > 0 && state.data.map((item) => {
                    keyGridIndex += 1;

                    // console.log('ITEM GRID ', item);

                    const rowData = {
                      row: item
                    }

                    return <GridRow 
                      searchTerm={searchData}
                      pagination={state.pagination}
                      key={keyGridIndex} 
                      row={item} 
                      redirectTo={state.redirectTo}
                      updateStateFunction={state.updateStateFunction}
                      deleteEntry={state.deleteEntry}
                    />

                  })}
                </tbody>
              </table>
            </div>
    
            <div className="flex flex-row-reverse text-gray-600 py-5 content-center items-center">
              <div className='flex flex-row content-center items-center'>
                <div className='mr-5 text-gray-800'>{state.pagination.range[0]} - {state.pagination.range[1]} of {state.pagination.total}</div>
                <div className='flex flex-row content-center items-center text-gray-800'>
                  {!isFirstPage && (
                    <div className='cursor-pointer hover:text-gray-500'>
                      <KeyboardDoubleArrowLeftIcon onClick={async () => {await handleExecuteCallFirstPage();}} />
                    </div>
                  )}

                  {isFirstPage && (
                    <div className='text-gray-500'>
                      <KeyboardDoubleArrowLeftIcon />
                    </div>
                  )}

                  {!isFirstPage && (
                    <div className='cursor-pointer hover:text-gray-500'>
                      <KeyboardArrowLeftIcon onClick={async () => {await handleExecuteCallPreviousPage();}} />
                    </div>
                  )}

                  {isFirstPage && (
                    <div className='text-gray-500'>
                      <KeyboardArrowLeftIcon />
                    </div>
                  )}

                  {!isLastPage && (
                    <div className='cursor-pointer hover:text-gray-500'>
                      <KeyboardArrowRightIcon onClick={async () => {await handleExecuteCallNextPage(searchData);}} />
                    </div>
                  )}

                  {isLastPage && (
                    <div className='text-gray-500'>
                      <KeyboardArrowRightIcon />
                    </div>
                  )}

                  {!isLastPage && (
                    <div className='cursor-pointer hover:text-gray-500'>
                      <KeyboardDoubleArrowRightIcon onClick={async () => {await handleExecuteCallLastPage();}} />
                    </div>
                  )}

                  {isLastPage && (
                    <div className='text-gray-500'>
                      <KeyboardDoubleArrowRightIcon />
                    </div>
                  )}
                </div>

              </div>
              <div className='mr-5 flex flex-row content-center items-center text-gray-800'>
                <div className='mr-2'>Rows per page:</div>
                <div>
                  <select onChange={handleChangeActualLimit} className="select w-full max-w-xs border-gray-400">
                    {limitOptions && limitOptions.map((item) => {
                      if (state.pagination.limit && state.pagination.limit ===  item) {
                        return <option key={item} value={item} selected>{item}</option>
                      } else if (state.pagination.limit && state.pagination.limit !== item) {
                        return <option key={item} value={item}>{item}</option>
                      } else {
                        if (item === limitOptions[0]) {
                          return <option key={item} value={item} selected>{item}</option>
                        } else {
                          return <option key={item} value={item}>{item}</option>
                        }
                      }
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}
        {(!state.data || state.data.length <= 0) && (
          <div className="min-w-full text-center p-5 w-full">
            <h3 className="text-2xl italic">Data Not found!!!</h3>
          </div>  
        )}
      </div>
    </div>
  )
}